import style from '@modules/common/inline.module.css';
import { bg_color_class, text_color_class } from '../../../utils/theme.utils';

export const Button = ({ children, ...params }) => {
	return (
		<button
			className={`${bg_color_class} ${text_color_class} ${style.button} `}
			{...params}>
			{children}
		</button>
	);
};

import style from '@modules/partials/startup.module.css';
import { Title } from '../common/inline/Title';
import { EnterpriseCard } from '../common/card/EnterpriseCard';

const ENTERPRISE = [
	{
		name: 'Akata Goavana',
		city: 'Fianarantsoa',
		date: 'Nov 2021 - Oct 2022',
		logo: 'https://www.akata-goavana.com/_next/image?url=%2Fimages%2Flogo-akata.avif&w=64&q=75',
		description:
			"Chargé des conceptions et réalisations des applications et sites sous Laravel et PHP, ainsi que sur WordPress. Ça m'a permis de faire un permier pas en tant que développeur au sein d'une entreprise, même si à ce moment là, j'étais encore un étudiant en 4ème année de formation à l'ENI Fianarantsoa",
	},
	{
		name: 'Saha Technology',
		city: 'Tananarive',
		date: 'Nov 2022 - maintenant',
		logo: 'https://media.licdn.com/dms/image/C4E0BAQG1wbRNYirFNA/company-logo_200_200/0/1652767586334/saha_technology_logo?e=1726099200&v=beta&t=mc-Nfvut_qE-LvmKkI1fvwYjWKJCJGc4T7yyX5hfjmE',
		description:
			"En charge de mettre en oeuvre les demandes clientes, toujours sur mes technologies de prédilections à savoir Laravel et WordPress( PHP ), ainsi que VueJS ( JS ), j'ai surtout pu mettre en avant tout mon savoir-faire.",
	},
];

export const Startup = () => {
	return (
		<div className={style.container}>
			<Title title={'Les entreprises auxquelles je suis passé'} />

			<div className={style.card__container}>
				{ENTERPRISE.map((item, index) => (
					<EnterpriseCard key={index} enterprise={item} />
				))}
			</div>
		</div>
	);
};

import {
	bg_color_class,
	bg_color_inverse,
	text_color_class,
} from '../../../utils/theme.utils';

export const MenuOverlay = ({ showMenu, style, closeMenu, children }) => {
	return (
		<div
			className={
				(showMenu &&
					`${style.menu__overlay} ${bg_color_class} ${text_color_class}`) ||
				''
			}>
			{showMenu && (
				<div className={`${style.close__container}`} onClick={closeMenu}>
					<div className={`${style.close__icon} ${bg_color_inverse}`}></div>
					<div className={`${style.close__icon} ${bg_color_inverse}`}></div>
				</div>
			)}
			{children}
		</div>
	);
};

import style from '@modules/partials/works.module.css';
import { Title } from '../common/inline/Title';
import { WorkCard } from '../common/card/WorkCard';

const PROJECTS = [
	{
		name: 'Discoly',
		description: `Le projet est un système de crawling de
        site web, avec récuperation des données du Google Search Console pour
        pouvoir proposer des KPIs liés à la perfomence du site.`,
		image: '/images/project/discoly.png',
		technologies: ['PHP', 'Laravel', 'VueJS', 'MySQL', 'Python', 'GCS Google'],
		style: 'lg:col-span-2 lg:row-span-2',
	},
	{
		name: 'Portfolio',
		description: `C'est mon projet personnel, qui est mon portfolio. En plus d'avoir utilisé ReactJS, j'ai aussi utilisé GSAP et CSS3 pour les animations et les designs`,
		image: '/images/project/portfolio.png',
		technologies: ['ReactJS', 'JavaScript', 'TailwindCSS', 'GSAP'],
		style: 'col-span-1',
	},
	{
		name: 'Adebaka Crypto',
		description: `Plateforme d'achat et de vente de NFT comme OpenSea, mais avec plus de fonctionnalité, comme la messagerie entre utilisateur, la vente aux enchères avec possibilité de payer en $ ou en crypto, création automatique de wallet et synchronisation automatique...`,
		image: '/images/project/adebaka.png',
		technologies: [
			'VueJS',
			'JavaScript',
			'Bootstrap',
			'GSAP',
			'Web3',
			'Solidity',
			'NodeJS',
			'MongoDB',
			'MySQL',
			'Laravel 10',
		],
		style: 'col-span-1',
	},
	{
		name: 'MAPIM Immo Logiciel',
		description: `Plateforme de gestion d'immobilier pour agence, qui offre beaucoup de fonctionnalité de gestion, comme les annonces automatique vers les plateformes de diffusion, la génération automatique des contenus depuis ChatGPT, synchronisation automatique de calendrier ainsi que d'autres.`,
		image: '/images/project/logiciel.png',
		technologies: [
			'ReactJS',
			'JavaScript',
			'TailwindCSS',
			'GSAP',
			'MySQL',
			'Google Calendar',
			'Laravel 10',
		],
		style: 'lg:col-span-2',
	},
	{
		name: 'Package Service Maker',
		description: `C'est un package Laravel qui permet de créer automatiquement des classes "Service", utilisé dans l'application pour étendre des fonctionnalités de Laravel.`,
		image: '/images/project/laravel_package.png',
		technologies: ['PHP', 'Laravel', 'Packagist'],
		style: 'lg:col-span-2',
	},
	{
		name: 'Package Service Maker',
		description: `C'est un package Laravel qui permet de créer automatiquement des routes comme celui dans api.php, et qui va donc permettre de structurer les routes normalements.`,
		image: '/images/project/custom_router.png',
		technologies: ['PHP', 'Laravel', 'Packagist'],
		style: 'lg:col-span-2',
	},
];

const cols = `lg:grid-cols-4`;

export const Works = () => {
	const grid_cols = cols;
	return (
		<div className={style.container}>
			<Title title={'Projets realisés'} />
			<div className={`${style.projects__container} ${grid_cols}`}>
				{PROJECTS.map((item, index) => (
					<WorkCard work={item} key={index} />
				))}
			</div>
		</div>
	);
};

import { CursusFeature } from '../../features/curcus/CursusFeature';
import { text_color_class } from '../../utils/theme.utils';
import { Title } from '../common/inline/Title';

export const Cursus = () => {
	return (
		<>
			<Title title={'Mon parcours universitaire'} />
			<p className={`${text_color_class} ${'text-center my-4'}`}>
				Passé entièrement à l'ENI, l'École Nationale de l'Informatique, qui
				forme les meilleurs ingénieurs de la Grande Île.
			</p>
			<CursusFeature />
		</>
	);
};

import style from '@modules/partials/works.module.css';
import { text_color_class } from '../../utils/theme.utils';

export const Banner = ({ work }) => {
	return (
		<div className={`${style.banner} ${text_color_class}`}>
			{work.technologies.join(' - ')}
		</div>
	);
};

import style from '@modules/partials/cursus.module.css';
import { bg_color_inverse } from '../../utils/theme.utils';
import { CursusCard } from '../../components/common/card/CursusCard';
import { Separator } from '../../components/common/card/Separator';

const CURSUS_UNIV = [
	{
		date: 'Jan 2023 - Nov 2023',
		title: '2ème Année de Master Professionnel en Informatique Générale',
		top: true,
		index: 1,
	},
	{
		date: 'Oct. 2021 - Oct. 2022',
		title: '1ère Année de Master Professionnel en Informatique Générale',
		top: false,
		index: 2,
	},
	{
		date: 'Fév 2021 - Juil 2021',
		title: '3ème Année de Licence Professionnelle en Informatique Générale',
		top: true,
		index: 3,
	},
	{
		date: 'Mars 2019 - Fév 2020',
		title: '2ème Année de Licence Professionnelle en Informatique Générale',
		top: false,
		index: 4,
	},
	{
		date: 'Mars 2018 - Nov 2018',
		title: '1ère Année de Licence Professionnelle en Informatique Générale',
		top: true,
		index: 5,
	},
];

const grid_cols = `grid-cols-5`;

export const CursusFeature = () => {
	const gridTemplateCols = grid_cols;
	const REVERSED_CURSUS = CURSUS_UNIV.sort((a, b) => b.index - a.index);
	return (
		<div className={style.cursus__container}>
			<div className={style.cursus__pc}>
				<div className={`${style.cursus__pc__chrono} ${gridTemplateCols}`}>
					{REVERSED_CURSUS.map((item, index) => {
						const colStart = `col-start-` + index;
						return (
							(item.top && (
								<CursusCard
									variant='pc'
									key={index}
									cursus={item}
									start={colStart}
								/>
							)) || <div className='col-span-1' key={index}></div>
						);
					})}
				</div>
				<div className={style.separator}>
					{REVERSED_CURSUS.map((item, index) => {
						return <Separator key={index} cursus={item} />;
					})}
				</div>
				<div className={`${style.cursus__pc__chrono} ${gridTemplateCols}`}>
					{REVERSED_CURSUS.map((item, index) => {
						const colStart = `col-start-` + index;
						return (
							(!item.top && (
								<CursusCard
									variant='pc'
									key={index}
									cursus={item}
									start={colStart}
								/>
							)) || <div className='col-span-1' key={index}></div>
						);
					})}
				</div>
			</div>
			<div className={style.cursus__mobile}>
				<div
					className={`${style.cursus__mobile__chrono} ${bg_color_inverse}`}></div>
				<div className={`${style.cursus__items}`}>
					{CURSUS_UNIV.map((item, index) => (
						<CursusCard cursus={item} key={index} />
					))}
				</div>
			</div>
		</div>
	);
};

import style from '@modules/partials/about.module.css';
import { text_color_class } from '../../utils/theme.utils';
import { Title } from '../common/inline/Title';
export const About = () => {
	return (
		<div className={style.about__container}>
			<Title title={'À propos de moi'} />
			<div className={style.about__section}>
				<div className={style.about__img}>
					<img src='/images/clairmont.jpg' alt='' />
				</div>
				<div className={`${style.about__description} ${text_color_class}`}>
					<p>
						Je suis un informaticien qui aime les défis quotidiens, et je fais
						de tout mon possible pour les surmonter. Ma devise est " Believe you
						can and you're halfway there ". C'est dans cet optique que je fais
						tout mon travail, en se basant sur la doctrine que tout est possible
						pour celui qui veut, car " vouloir c'est pouvoir ". Etant un
						étudiant en Informatique à l'Ecole Nationale d'Informatique de
						Fianarantsoa, j'aime surtout la programmation. Je m'estime être un
						développeur backend avec des solides base dans le langage PHP, et
						ses frameworks, mais ça ne m'empêche en rien de varier mes
						competences et ainsi développer des interfaces graphiques en
						frontend. J'aime surtout PHP et JavaScript comme langage de
						programmation. Non satisfait de ce que j'ai déjà connu jusqu'ici, je
						tends aussi à me refaire un pétit bout de chemin vers Rust, ainsi
						que les crypto-monnaies et le micro service.
					</p>
				</div>
			</div>
		</div>
	);
};

import './App.css';
import { Spinner } from './components/loading/Spinner';
import { MobileMenu } from './components/menu/mobile/MobileMenu';
import { Hero } from './components/partials/Hero';
import { Contact } from './components/contact/Contact';
import { About } from './components/partials/About';
import { bg_color_class } from './utils/theme.utils';
import { Service } from './components/partials/Service';
import { Skills } from './components/partials/Skills';
import { Cursus } from './components/partials/Curscus';
import { Startup } from './components/partials/Startup';
import { Works } from './components/partials/Works';
import { Footer } from './components/footer/Footer';

function App() {
	return (
		<div className={`App ${bg_color_class}`}>
			<MobileMenu />
			<Spinner />
			<Hero />
			<Contact />
			<About />
			<Service />
			<Skills />
			<Cursus />
			<Startup />
			<Works />
			<Footer />
		</div>
	);
}

export default App;

import { text_color_class } from '../../utils/theme.utils';
import style from '@modules/partials/works.module.css';

export const WorkDetails = ({ work }) => {
	return (
		<div className={`${text_color_class} ${style.work__details}`}>
			<img src={`${work.image}`} alt='' />
			<ul className={style.details}>
				<li>
					<b>Titre du projet</b> : {work.name}
				</li>
				<li>
					<b>Technologies et outils utilisés</b> :{' '}
					{work.technologies.join(' - ')}
				</li>
				<li>
					<b>Description</b> : {work.description}
				</li>
			</ul>
		</div>
	);
};

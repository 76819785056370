import style from '@modules/partials/works.module.css';
import { text_color_class } from '../../../utils/theme.utils';
import { Banner } from '../../../features/works/Banner';
import { useState } from 'react';
import { ModalService } from '../../../features/services/ModalService';
import { WorkDetails } from '../../../features/works/WordDetails';
export const WorkCard = ({ work }) => {
	const [show, setShow] = useState(false);
	return (
		<>
			<div
				className={`${work.style} ${style.card} ${text_color_class}`}
				onClick={() => setShow(true)}>
				<img src={`${work.image}`} alt='' />
				<Banner work={work} />
			</div>

			<ModalService show={show} handleClose={() => setShow(false)}>
				<WorkDetails work={work} />
			</ModalService>
		</>
	);
};

import style from '@modules/partials/hero.module.css';
import { text_color_class } from '../../utils/theme.utils';

export const Hero = () => {
	return (
		<div className={style.hero__container}>
			<div className={`${style.hero__title} ${text_color_class}`}>
				Changer la perception humaine avec la technologie
			</div>
			<img
				className={style.hero__image}
				src='/images/hero.png'
				alt='Rocket spaceship'
			/>
		</div>
	);
};

import style from '@modules/services/service.module.css';
import { createPortal } from 'react-dom';
import menu_style from '@modules/menu/menu.module.css';
import { text_color_class } from '../../utils/theme.utils';
import { MenuOverlay } from '../../components/menu/mobile/MenuOverlay';
export const ModalService = ({ children, show, handleClose }) => {
	return (
		show &&
		createPortal(
			<div className={`${style.modal__service} ${text_color_class}`}>
				<MenuOverlay showMenu={true} closeMenu={handleClose} style={menu_style}>
					<div className={style.children}>{children}</div>
				</MenuOverlay>
			</div>,
			document.getElementById('root')
		)
	);
};

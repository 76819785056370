import style from '@modules/services/service.module.css';
import { text_color_class } from '../../utils/theme.utils';
import { ServiceCard } from '../../components/common/card/ServiceCard';
import { useRef } from 'react';
// import { randomIntFromInterval } from '../../utils/random.utils';

export const SERVICES = [
	"Conception de Système d'Information",
	"Développement d'application web",
	'Développement de solution e-commerce',
	'Optimisation de site',
];

export const ServiceFeatures = () => {
	const containerRef = useRef(null);

	// const launchFunction = () => {
	// 	const elements = [...containerRef.current.childNodes];
	// 	const elementsTo = elements.filter(
	// 		(item) => !item.className.includes('centered')
	// 	);
	// 	let classNames = elementsTo.map((item) => item.className);

	// 	for (let i = 0; i < elementsTo.length; i++) {
	// 		let randomIndex = randomIntFromInterval(0, classNames.length - 1);
	// 		elementsTo[i].className = classNames[randomIndex];
	// 		classNames = classNames.slice(randomIndex, 1);
	// 	}
	// };

	return (
		<div
			ref={containerRef}
			className={`${style.service__container} ${text_color_class}`}>
			<div className={`${style.service__one} ${style.card__service}`}>
				<ServiceCard title={"Conception de Système d'Information"} />
			</div>
			<div className={`${style.service__two}  ${style.card__service}`}>
				<ServiceCard title={"Développement d'application web"} />
			</div>
			<div className={`${style.service__two} ${style.centered__service}`}>
				<img src='/images/icons/idea.png' alt='' />
			</div>
			<div className={`${style.service__three}  ${style.card__service}`}>
				<ServiceCard title={'Développement de solution e-commerce'} />
			</div>
			<div className={`${style.service__four}  ${style.card__service}`}>
				<ServiceCard title={'Optimisation de site'} />
			</div>
		</div>
	);
};

import { ServiceFeatures } from '../../features/services/ServiceFeatures';
import { Title } from '../common/inline/Title';

export const Service = () => {
	return (
		<>
			<Title title={'Mes services'} />
			<ServiceFeatures />
		</>
	);
};

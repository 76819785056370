import { bg_color_inverse } from '../../utils/theme.utils';
import { SkillCard } from '../common/card/SkillCard';
import { Title } from '../common/inline/Title';
import style from '@modules/partials/skills.module.css';

const FRAMEWORKS = [
	'Laravel',
	'Tailwind',
	'ReactJS',
	'VueJS',
	'Nuxt',
	'WordPress',
	'ExpressJS',
	'NodeJS',
	'WooCommerce',
];
const SKILLS = [
	'PHP',
	'HTML',
	'CSS',
	'JavaScript',
	'TypeScript',
	'Rust',
	'Solidity',
];

const HARD = ['Git', 'GitLab', 'GitHub', 'Etherum', 'MySQL', 'MongoDB'];

export const Skills = () => {
	return (
		<>
			<Title title={'Mes compétences'} />
			<div className={style.skills__container}>
				<div className={`${bg_color_inverse} ${style.skills__coding}`}></div>
				<div className={style.skills}>
					<div className={style.skills_items}>
						{SKILLS.map((item, index) => (
							<SkillCard skill={item} key={index} />
						))}
					</div>
					<div className={style.skills_items}>
						{FRAMEWORKS.map((item, index) => (
							<SkillCard skill={item} key={index} />
						))}
					</div>

					<div className={style.skills_items}>
						{HARD.map((item, index) => (
							<SkillCard skill={item} key={index} />
						))}
					</div>
				</div>
			</div>
		</>
	);
};

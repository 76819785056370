import style from '@modules/menu/menu.module.css';
import { Switcher } from '../../../features/theme/Switcher';
export const MobileMenu = () => {
	return (
		<>
			<div className={style.menu__mobile}>
				<div className={style.menu__mobile__container}>
					<Switcher />
					<div className={style.burger__menu__container}>
						<img src='/logo.png' alt='' />
					</div>
				</div>
			</div>
		</>
	);
};

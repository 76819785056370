import { useState } from 'react';
import { THEME, THEME_COLOR, theme } from '../../utils/theme.utils';
import style from '@modules/theme/switcher.module.css';
import { ThemeService } from '../../services/theme.service';
export const Switcher = () => {
	let [currentTheme, setCurrentTheme] = useState(theme);

	const switchTheme = (newTheme) => {
		setCurrentTheme(newTheme);
		ThemeService.switchTheme(newTheme).then(() => window.location.reload());
	};
	return (
		<div className={style.switcher__container}>
			{(currentTheme === THEME_COLOR.DARK && (
				<img
					src='/images/icons/sun.png'
					alt=''
					onClick={() => switchTheme(THEME.LIGHT)}
				/>
			)) || (
				<img
					src='/images/icons/moon.png'
					alt=''
					onClick={() => switchTheme(THEME.DARK)}
				/>
			)}
		</div>
	);
};

import style from '@modules/partials/startup.module.css';
import { text_color_class } from '../../../utils/theme.utils';
import { useState } from 'react';

export const EnterpriseCard = ({ enterprise }) => {
	const [flipped, setFlipped] = useState(null);

	const handleFlip = () => {
		let current_flipped = flipped;
		if (flipped == null) current_flipped = false;
		setFlipped(!current_flipped);
	};

	return (
		<div
			onClick={handleFlip}
			className={`${style.card} ${flipped === false ? style.rotate : ''} ${
				flipped === true ? style.rotate__reverse : ''
			}`}>
			{(!flipped && (
				<>
					<img
						src={enterprise.logo}
						alt='Logo enterprise'
						className={style.logo}
					/>
					<div className={`${style.description} ${text_color_class}`}>
						<h1>{enterprise.name}</h1>
						<span>({enterprise.date})</span>
					</div>
				</>
			)) || (
				<div className={text_color_class}>
					<p className={style.backdrop}>{enterprise.description}</p>
				</div>
			)}
		</div>
	);
};

import {
	bg_color_inverse,
	text_color_inverse,
} from '../../../utils/theme.utils';
import style from '@modules/partials/cursus.module.css';
export const Separator = ({ cursus }) => {
	return (
		<div className={style.hr}>
			<div className={`${bg_color_inverse} ${style.separator__item}`}>
				<div
					className={`${text_color_inverse} ${style.separator__item__date} !important`}>
					{cursus.date}
				</div>
			</div>
		</div>
	);
};

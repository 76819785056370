import style from '@modules/partials/cursus.module.css';
import {
	shadow_color_inverse,
	text_color_class,
} from '../../../utils/theme.utils';

export const CursusCard = ({ variant = 'mobile', cursus, start }) => {
	return (
		(variant === 'mobile' && (
			<div
				className={`${style.cursus__card} ${shadow_color_inverse} ${text_color_class}`}>
				<h1>
					<b>Date</b> : {cursus.date}
				</h1>
				<span>
					<b>Parcours</b> : {cursus.title}
				</span>
			</div>
		)) || (
			<div
				className={`${style.cursus__card__pc} ${start} ${shadow_color_inverse} ${text_color_class}`}>
				{cursus.title}
			</div>
		)
	);
};

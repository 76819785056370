import style from '@modules/loading/spinner.module.css';
import { useEffect, useState } from 'react';
export const Spinner = () => {
	const [showLoading, setShowLoading] = useState(true);
	useEffect(() => {
		const timer = setTimeout(() => setShowLoading(false), 2000);
		return () => clearTimeout(timer);
	}, []);
	return (
		showLoading && (
			<div className={style.loading__container} style={{ zIndex: 10000 }}>
				<div className={style.scene}>
					<div className={style.cube__wrapper}>
						<div className={style.cube}>
							<div className={style.cube__faces}>
								<div className={`${style.cube__face} ${style.shadow}`}></div>
								<div className={`${style.cube__face} ${style.bottom}`}></div>
								<div className={`${style.cube__face} ${style.top}`}></div>
								<div className={`${style.cube__face} ${style.left}`}></div>
								<div className={`${style.cube__face} ${style.right}`}></div>
								<div className={`${style.cube__face} ${style.back}`}></div>
								<div className={`${style.cube__face} ${style.front}`}></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

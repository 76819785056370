import style from '@modules/partials/contact.module.css';
import { Button } from '../common/inline/Button';
export const Contact = () => {
	const downloadCV = () => {
		window.location.href = '/docs/CV_RAJAONARISON_Clairmont.pdf';
	};

	return (
		<>
			<div className={style.contact__container}>
				<div className={style.contact__discord}>
					<a href='mailto:clairmont.rajaonarison@gmail.com'>
						<img src='/images/icons/gmail.png' alt='Gmail' />
					</a>
				</div>

				<div className={style.contact__github}>
					<a href='https://github.com/masterSkill77'>
						<img src='/images/icons/github.png' alt='Github' />
					</a>
				</div>
				<div className={style.contact__linkedin}>
					<a href='www.linkedin.com/in/clairmont-rajaonarison-8073271a2'>
						<img src='/images/icons/linkedin.png' alt='Linkedin' />
					</a>
				</div>
				<div className={style.contact__github}>
					<a href='https://packagist.org/packages/masterskill/'>
						<img src='/images/icons/packagist.png' alt='Linkedin' />
					</a>
				</div>
			</div>
			<div className={style.contact__cta}>
				<Button>Contactez-moi</Button>
				<Button onClick={downloadCV}>Télécharger mon CV</Button>
			</div>
		</>
	);
};

import style from '@modules/theme/footer.module.css';
import { text_color_class } from '../../utils/theme.utils';
import { SERVICES } from '../../features/services/ServiceFeatures';

export const Footer = () => {
	return (
		<>
			<div className={`${style.container} ${text_color_class}`}>
				<div className={style.container__info}>
					<img src='/logo.png' alt='' />
					<div>
						<ul>
							<li>Tél : +261 34 68 047 17</li>
							<li>Mail : clairmont.rajaonarison@gmail.com</li>
						</ul>
					</div>
				</div>
				<div className={style.container__info}>
					<h1>Mes Services</h1>
					<ul>
						{SERVICES.map((item, index) => (
							<li key={index}>{item}</li>
						))}
					</ul>
				</div>
				<div className={style.container__copyright}>
					Copyright {new Date().getFullYear()} - Clairmont RAJAONARISON
				</div>
			</div>
		</>
	);
};

import style from '@modules/services/service.module.css';

export const ServiceCard = ({ title, description }) => {
	return (
		<>
			<div className={style.service__card}>
				<img src='/images/icons/service_logo.png' alt='' />
				<h1>{title}</h1>
			</div>
		</>
	);
};

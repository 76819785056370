export const THEME_ITEM_NAME = 'theme';
export const THEME = {
	DARK: 'dark',
	LIGHT: 'light',
};

export const THEME_COLOR = {
	DARK: 'black',
	LIGHT: 'white',
};

export const theme =
	localStorage.getItem(THEME_ITEM_NAME) === THEME.DARK
		? THEME_COLOR.LIGHT
		: THEME_COLOR.DARK;

export const text_color_class =
	theme === THEME_COLOR.DARK ? 'text-white' : 'text-black';
export const text_color_inverse =
	theme === THEME_COLOR.DARK ? 'text-black' : 'text-white';
export const bg_color_class = 'bg-' + theme;

export const bg_color_inverse =
	theme === THEME_COLOR.DARK ? 'bg-white' : 'bg-black';

export const shadow_color_class = 'shadow-' + theme;

export const shadow_color_inverse =
	theme === THEME_COLOR.DARK ? 'shadow-white' : 'shadow-black';
